import * as React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Paragraph from "../components/Paragraph"
import TextWrapper from "../components/TextWrapper"
import Title from "../components/Title"
import { List, ListItem } from "../components/List"

const Underlined = styled.span`
  text-decoration: underline;
`

const TeachingPage = () => (
  <Layout>
    <SEO title="Teaching responsibilities" />
    <TextWrapper>
      <Paragraph>
        Following his retirement, Yannis XENAKIS assumed teaching
        responsibilities at universities in Germany and France. He specialises
        in explaining the EU economy and European environmental policy.
      </Paragraph>
      <Title>European Economic Integration</Title>
      <Paragraph>
        Yannis XENAKIS teaches European Economic Integration at two levels.
      </Paragraph>
      <Paragraph>
        At introductory level, the aim is to explain the basics for the
        functioning of the EU economy, including: (i) concepts, such as internal
        market, Schengen; (ii) principles, such as stability and sound public
        finance; and (iii) institutions/mechanisms, such as the European Central
        Bank, Stability and Growth Pact, euro banknotes and coins.
      </Paragraph>
      <Paragraph>
        At advanced level, teaching covers the main economic policies of the EU:
        the European Green Deal, the Multiannual Financial Framework, monetary
        policy, the European Semester, as well as the Common Agricultural
        Policy, Competition, and Trade policies.
      </Paragraph>
      <Title>European Environmental Policy</Title>
      <Paragraph>
        In the present context of increasing urgency to fight against climate
        change and preserve the environment, Yannis XENAKIS explains the main
        lines of the environmental policy of the European Union, the world
        leader in this domain. The course describes the goals and aims of the
        European Green Deal, placed in the broader international framework of
        the United Nations.
      </Paragraph>
      <Paragraph>
        On the topic of climate, the course focuses on the Emissions Trading
        System, the key, market-friendly mechanism for reducing greenhouse
        emissions, and on the action taken in the domains of transport, forests
        and land.
      </Paragraph>
      <Paragraph>
        On the topic of the environment, emphasis is placed on the efforts to
        preserve biodiversity, circular economy and protection of health.
      </Paragraph>
      <Paragraph>
        The discussion of energy addresses electrification and renewable sources
        of energy, as well as the EU’s efforts to identify sustainable economic
        activities despite the controversy this causes.
      </Paragraph>
      <Title>University positions</Title>
      <Paragraph>
        Yannis XENAKIS teaches university in France and Germany.
      </Paragraph>
      <Paragraph>
        <Underlined>France</Underlined>, Catholic University of Toulouse (ICT),
        undergraduate: European Economy (2016-2022).
      </Paragraph>
      <Paragraph>
        <Underlined>Germany</Underlined>, visiting Professor at the
        Europa-Universität Viadrina, Frankfurt/Oder, Master in European Studies:
        European Economy (2018-2021) - EU Environmental Policy (2022-2025).
      </Paragraph>
      <Paragraph>
        <Underlined>Germany</Underlined>, Fulda University of Applied Sciences,
        undergraduate: European Economy, (2020-2023); Master (ICEUS): European
        Economic Integration (2019-2025); EU Environmental Policy (2021-2025).
      </Paragraph>

      <Title>Seminars and speeches</Title>
      <Paragraph>
        In addition to the regular courses, Yannis XENAKIS has been delivering
        ad hoc specialised lectures and seminars.
      </Paragraph>
      <List>
        <ListItem>
          "Protection against customs fraud", for Master of Customs
          Administration (2015) at the University of Münster.
        </ListItem>
        <ListItem>
          "Le financement de l’Union européenne" (2016) at the Catholic
          University of Toulouse.
        </ListItem>
        <ListItem>
          "L’Europe d’aujourd’hui ; l’Europe de demain" public speech (cérémonie
          de proclamation) at the Catholic University of Toulouse.
        </ListItem>
        <ListItem>
          "Work and life in the European Commission: my experience" (2019) at
          the Fulda University of Applied Sciences.
        </ListItem>
        <ListItem>
          "Europe in the world: Conflicts, Politics, Values" (2023): public
          lecture at the Fulda University of Applied Sciences.
        </ListItem>
      </List>
      <Title>Other</Title>
      <Paragraph>
        Collaboration with the research consultancy Ecorys (2021-2024).
      </Paragraph>
    </TextWrapper>
  </Layout>
)

export default TeachingPage
